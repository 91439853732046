import * as React from "react"
import Layout from "../components/layout"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { css } from "@emotion/core"

const HM = styled.h1`
  color: rgba(37, 50, 67, 1);
  font-size: calc((12px + 2vh) + 3vw);
  min-width: 450px;
`

const MP = styled.div`
  padding-bottom: 4.25rem;
  padding-top: 4.25rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  text-align: center;
  margin: 0px auto;
  border-bottem: ;
`

const Description = styled.div`
  width: 100%;
  padding: 0px 1.5rem 1.5rem;
  border-bottom: 1px solid rgb(246, 237, 250);
  border-top: 1px solid rgb(246, 237, 250);
  background: rgb(251, 242, 239);
`

const Content = styled.div`
  max-width: 42rem;
  margin: 0 auto;
  padding: 1.5rem;
  position: static;
  padding-top: 2rem;
`

const Tag = styled.p`
  font-size: 1.5rem;
  color: rgba(37, 50, 67, 1);
`

interface Props {
  readonly data: AboutData
}

export default class IndexPage extends React.Component<Props> {
  public render() {
    const { data } = this.props
    const about = data.allMarkdownRemark.edges[0]

    return (
      <Layout>
        <MP>
          <HM>Alexa open &quot;Be Happy!&quot;</HM>
          <Tag>Tips, tricks, life-hacks to support happiness</Tag>
        </MP>
        <Description>
          <Content dangerouslySetInnerHTML={{ __html: about.node.html }} />
        </Description>
      </Layout>
    )
  }
}

interface AboutData {
  allMarkdownRemark: {
    edges: {
      node: {
        html: string
        frontmatter: {
          title: string
        }
      }
    }[]
  }
}

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { slug: { eq: "about" } } }) {
      edges {
        node {
          html
          frontmatter {
            title
          }
        }
      }
    }
  }
`
